import { LoadingButton } from '@mui/lab';
import { Typography } from '@mui/material';
import useLocales from '#/hooks/useLocales';

type Props = {
  handleBuyMore: () => void;
  membersNo: number;
  userPackage?: 'starter' | 'advanced' | 'no' | 'family_member' | undefined;
  isLoading?: boolean;
};

export default function BuyMore({
  handleBuyMore,
  membersNo,
  userPackage,
  isLoading,
}: Props) {
  const { translate } = useLocales();
  const disabled = userPackage === 'family_member' && membersNo < 2;

  return (
    <LoadingButton
      onClick={handleBuyMore}
      disabled={membersNo === 0 || disabled}
      variant="contained"
      loading={isLoading}
    >
      <Typography>
        {userPackage === 'family_member'
          ? String(translate('provisionLanding.pricing.purchaseButton'))
          : String(
              translate('provisionLanding.addMore', {
                membersNo,
              })
            )}
      </Typography>
    </LoadingButton>
  );
}
