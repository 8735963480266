import { Stack, TextField } from '@mui/material';
import { ChangeEvent } from 'react';
import useLocales from '#/hooks/useLocales';

type MembersNumberProps = {
  membersNo: number;
  handleMembersNo: (
    e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => void;
  disabled?: boolean;
};

export default function MembersNumber({
  membersNo,
  handleMembersNo,
  disabled,
}: MembersNumberProps) {
  const { translate } = useLocales();
  return (
    <Stack
      direction="column"
      alignItems="center"
      justifyContent="space-between"
      spacing={2}
    >
      <TextField
        size="small"
        name="membersNo"
        placeholder={String(translate('global.howManyMembers'))}
        type="number"
        disabled={disabled}
        InputLabelProps={{
          shrink: true,
        }}
        variant="outlined"
        onChange={handleMembersNo}
      />
    </Stack>
  );
}
