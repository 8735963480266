import queryClient from '#/api/query-client';
import { useGet } from '#/api/reactQuery';

const baseURL = '/subscription';

export const BillingEndpoints = {
  purchasePackage: () => `${baseURL}/get-checkout-session/`,
  managePurchase: () => `${baseURL}/get-billing-portal-session/`,
};

export const invalidateBillingQueries = {
  purchasePackage: () => {
    queryClient
      .invalidateQueries(BillingEndpoints.purchasePackage())
      .then((r) => r);
  },
};

type PurchasePackageResponse = {
  session: {
    url: string;
  };
};

export const usePurchasePackage = (
  product: string,
  enabled: boolean,
  quantity?: number,
  partner?: string
) =>
  useGet<PurchasePackageResponse>(
    BillingEndpoints.purchasePackage(),
    {
      product,
      quantity,
      partner,
    },
    {
      enabled,
    }
  );

export const useManagePurchase = (enabled?: boolean) =>
  useGet<PurchasePackageResponse>(
    BillingEndpoints.managePurchase(),
    {},
    {
      enabled,
    }
  );
